export const PRICE_DEFAULT = [
    {
        "fullName": "Afghanistan",
        "name": "Afghanistan",
        "price": "0.3315",
        "code": "AF",
        "emoji": "🇦🇫",
        "unicode": "U+1F1E6 U+1F1EB",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AF.svg"
    },
    {
        "fullName": "Albania",
        "name": "Albania",
        "price": "0.0872",
        "code": "AL",
        "emoji": "🇦🇱",
        "unicode": "U+1F1E6 U+1F1F1",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AL.svg"
    },
    {
        "fullName": "Algeria",
        "name": "Algeria",
        "price": "0.2523",
        "code": "DZ",
        "emoji": "🇩🇿",
        "unicode": "U+1F1E9 U+1F1FF",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DZ.svg"
    },
    {
        "fullName": "American Samoa",
        "name": "American Samoa",
        "price": "0.1350",
        "code": "AS",
        "emoji": "🇦🇸",
        "unicode": "U+1F1E6 U+1F1F8",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AS.svg"
    },
    {
        "fullName": "Andorra",
        "name": "Andorra",
        "price": "0.1356",
        "code": "AD",
        "emoji": "🇦🇩",
        "unicode": "U+1F1E6 U+1F1E9",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AD.svg"
    },
    {
        "fullName": "Angola",
        "name": "Angola",
        "price": "0.0410",
        "code": "AO",
        "emoji": "🇦🇴",
        "unicode": "U+1F1E6 U+1F1F4",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AO.svg"
    },
    {
        "fullName": "Anguilla",
        "name": "Anguilla",
        "price": "0.1200",
        "code": "AI",
        "emoji": "🇦🇮",
        "unicode": "U+1F1E6 U+1F1EE",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AI.svg"
    },
    {
        "fullName": "Antigua and Barbuda",
        "name": "Antigua & Barbuda",
        "price": "0.1526",
        "code": "AG",
        "emoji": "🇦🇬",
        "unicode": "U+1F1E6 U+1F1EC",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AG.svg"
    },
    {
        "fullName": "Argentina",
        "name": "Argentina",
        "price": "0.0857",
        "code": "AR",
        "emoji": "🇦🇷",
        "unicode": "U+1F1E6 U+1F1F7",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AR.svg"
    },
    {
        "fullName": "Armenia",
        "name": "Armenia",
        "price": "0.1490",
        "code": "AM",
        "emoji": "🇦🇲",
        "unicode": "U+1F1E6 U+1F1F2",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AM.svg"
    },
    {
        "fullName": "Aruba",
        "name": "Aruba",
        "price": "0.1350",
        "code": "AW",
        "emoji": "🇦🇼",
        "unicode": "U+1F1E6 U+1F1FC",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AW.svg"
    },
    {
        "fullName": "Ascension",
        "name": "Ascension Island",
        "price": "0.1350",
        "code": "AC",
        "emoji": "🇦🇨",
        "unicode": "U+1F1E6 U+1F1E8",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AC.svg"
    },
    {
        "fullName": "Australia",
        "name": "Australia",
        "price": "0.0266",
        "code": "AU",
        "emoji": "🇦🇺",
        "unicode": "U+1F1E6 U+1F1FA",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AU.svg"
    },
    {
        "fullName": "Austria",
        "name": "Austria",
        "price": "0.0366",
        "code": "AT",
        "emoji": "🇦🇹",
        "unicode": "U+1F1E6 U+1F1F9",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AT.svg"
    },
    {
        "fullName": "Azerbaijan",
        "name": "Azerbaijan",
        "price": "0.4089",
        "code": "AZ",
        "emoji": "🇦🇿",
        "unicode": "U+1F1E6 U+1F1FF",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AZ.svg"
    },
    {
        "fullName": "Bahamas",
        "name": "Bahamas",
        "price": "0.1350",
        "code": "BS",
        "emoji": "🇧🇸",
        "unicode": "U+1F1E7 U+1F1F8",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BS.svg"
    },
    {
        "fullName": "Bahrain",
        "name": "Bahrain",
        "price": "0.0363",
        "code": "BH",
        "emoji": "🇧🇭",
        "unicode": "U+1F1E7 U+1F1ED",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg"
    },
    {
        "fullName": "Bangladesh",
        "name": "Bangladesh",
        "price": "0.3012",
        "code": "BD",
        "emoji": "🇧🇩",
        "unicode": "U+1F1E7 U+1F1E9",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BD.svg"
    },
    {
        "fullName": "Barbados",
        "name": "Barbados",
        "price": "0.1962",
        "code": "BB",
        "emoji": "🇧🇧",
        "unicode": "U+1F1E7 U+1F1E7",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BB.svg"
    },
    {
        "fullName": "Belarus",
        "name": "Belarus",
        "price": "0.2228",
        "code": "BY",
        "emoji": "🇧🇾",
        "unicode": "U+1F1E7 U+1F1FE",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BY.svg"
    },
    {
        "fullName": "Belgium",
        "name": "Belgium",
        "price": "0.0846",
        "code": "BE",
        "emoji": "🇧🇪",
        "unicode": "U+1F1E7 U+1F1EA",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BE.svg"
    },
    {
        "fullName": "Belize",
        "name": "Belize",
        "price": "0.2076",
        "code": "BZ",
        "emoji": "🇧🇿",
        "unicode": "U+1F1E7 U+1F1FF",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BZ.svg"
    },
    {
        "fullName": "Benin",
        "name": "Benin",
        "price": "0.1361",
        "code": "BJ",
        "emoji": "🇧🇯",
        "unicode": "U+1F1E7 U+1F1EF",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BJ.svg"
    },
    {
        "fullName": "Bermuda",
        "name": "Bermuda",
        "price": "0.2387",
        "code": "BM",
        "emoji": "🇧🇲",
        "unicode": "U+1F1E7 U+1F1F2",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BM.svg"
    },
    {
        "fullName": "Bhutan",
        "name": "Bhutan",
        "price": "0.4286",
        "code": "BT",
        "emoji": "🇧🇹",
        "unicode": "U+1F1E7 U+1F1F9",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BT.svg"
    },
    {
        "fullName": "Bolivia",
        "name": "Bolivia",
        "price": "0.1449",
        "code": "BO",
        "emoji": "🇧🇴",
        "unicode": "U+1F1E7 U+1F1F4",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BO.svg"
    },
    {
        "fullName": "Bonaire, Sabo & St. Eustacius",
        "name": "Caribbean Netherlands",
        "price": "0.2213",
        "code": "BQ",
        "emoji": "🇧🇶",
        "unicode": "U+1F1E7 U+1F1F6",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BQ.svg"
    },
    {
        "fullName": "Bosnia and Herzegovina",
        "name": "Bosnia & Herzegovina",
        "price": "0.0857",
        "code": "BA",
        "emoji": "🇧🇦",
        "unicode": "U+1F1E7 U+1F1E6",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BA.svg"
    },
    {
        "fullName": "Botswana",
        "name": "Botswana",
        "price": "0.0282",
        "code": "BW",
        "emoji": "🇧🇼",
        "unicode": "U+1F1E7 U+1F1FC",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BW.svg"
    },
    {
        "fullName": "Brazil",
        "name": "Brazil",
        "price": "0.0186",
        "code": "BR",
        "emoji": "🇧🇷",
        "unicode": "U+1F1E7 U+1F1F7",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BR.svg"
    },
    {
        "fullName": "British Virgin Islands",
        "name": "British Virgin Islands",
        "price": "0.1638",
        "code": "VG",
        "emoji": "🇻🇬",
        "unicode": "U+1F1FB U+1F1EC",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VG.svg"
    },
    {
        "fullName": "Brunei Darussalam",
        "name": "Brunei",
        "price": "0.1350",
        "code": "BN",
        "emoji": "🇧🇳",
        "unicode": "U+1F1E7 U+1F1F3",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BN.svg"
    },
    {
        "fullName": "Bulgaria",
        "name": "Bulgaria",
        "price": "0.1485",
        "code": "BG",
        "emoji": "🇧🇬",
        "unicode": "U+1F1E7 U+1F1EC",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BG.svg"
    },
    {
        "fullName": "Burkina Faso",
        "name": "Burkina Faso",
        "price": "0.1821",
        "code": "BF",
        "emoji": "🇧🇫",
        "unicode": "U+1F1E7 U+1F1EB",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BF.svg"
    },
    {
        "fullName": "Burundi",
        "name": "Burundi",
        "price": "0.3927",
        "code": "BI",
        "emoji": "🇧🇮",
        "unicode": "U+1F1E7 U+1F1EE",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BI.svg"
    },
    {
        "fullName": "Cambodia",
        "name": "Cambodia",
        "price": "0.2103",
        "code": "KH",
        "emoji": "🇰🇭",
        "unicode": "U+1F1F0 U+1F1ED",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KH.svg"
    },
    {
        "fullName": "Cameroon",
        "name": "Cameroon",
        "price": "0.1469",
        "code": "CM",
        "emoji": "🇨🇲",
        "unicode": "U+1F1E8 U+1F1F2",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CM.svg"
    },
    {
        "fullName": "Canada",
        "name": "Canada",
        "price": "0.0219",
        "code": "CA",
        "emoji": "🇨🇦",
        "unicode": "U+1F1E8 U+1F1E6",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CA.svg"
    },
    {
        "fullName": "Cape Verde",
        "name": "Cape Verde",
        "price": "0.1631",
        "code": "CV",
        "emoji": "🇨🇻",
        "unicode": "U+1F1E8 U+1F1FB",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CV.svg"
    },
    {
        "fullName": "Cayman Islands",
        "name": "Cayman Islands",
        "price": "0.1757",
        "code": "KY",
        "emoji": "🇰🇾",
        "unicode": "U+1F1F0 U+1F1FE",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KY.svg"
    },
    {
        "fullName": "Central African Republic",
        "name": "Central African Republic",
        "price": "0.1239",
        "code": "CF",
        "emoji": "🇨🇫",
        "unicode": "U+1F1E8 U+1F1EB",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CF.svg"
    },
    {
        "fullName": "Chad",
        "name": "Chad",
        "price": "0.2867",
        "code": "TD",
        "emoji": "🇹🇩",
        "unicode": "U+1F1F9 U+1F1E9",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TD.svg"
    },
    {
        "fullName": "Chile",
        "name": "Chile",
        "price": "0.0083",
        "code": "CL",
        "emoji": "🇨🇱",
        "unicode": "U+1F1E8 U+1F1F1",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CL.svg"
    },
    {
        "fullName": "China",
        "name": "China",
        "price": "0.0090",
        "code": "CN",
        "emoji": "🇨🇳",
        "unicode": "U+1F1E8 U+1F1F3",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CN.svg"
    },
    {
        "fullName": "Colombia",
        "name": "Colombia",
        "price": "0.0026",
        "code": "CO",
        "emoji": "🇨🇴",
        "unicode": "U+1F1E8 U+1F1F4",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CO.svg"
    },
    {
        "fullName": "Comoros",
        "name": "Comoros",
        "price": "0.3009",
        "code": "KM",
        "emoji": "🇰🇲",
        "unicode": "U+1F1F0 U+1F1F2",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KM.svg"
    },
    {
        "fullName": "Congo",
        "name": "Congo - Brazzaville",
        "price": "0.1233",
        "code": "CG",
        "emoji": "🇨🇬",
        "unicode": "U+1F1E8 U+1F1EC",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CG.svg"
    },
    {
        "fullName": "Cook Islands",
        "name": "Cook Islands",
        "price": "0.1350",
        "code": "CK",
        "emoji": "🇨🇰",
        "unicode": "U+1F1E8 U+1F1F0",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CK.svg"
    },
    {
        "fullName": "Costa Rica",
        "name": "Costa Rica",
        "price": "0.0411",
        "code": "CR",
        "emoji": "🇨🇷",
        "unicode": "U+1F1E8 U+1F1F7",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CR.svg"
    },
    {
        "fullName": "Cote d'Ivoire",
        "name": "Côte d’Ivoire",
        "price": "0.2058",
        "code": "CI",
        "emoji": "🇨🇮",
        "unicode": "U+1F1E8 U+1F1EE",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CI.svg"
    },
    {
        "fullName": "Croatia",
        "name": "Croatia",
        "price": "0.0893",
        "code": "HR",
        "emoji": "🇭🇷",
        "unicode": "U+1F1ED U+1F1F7",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HR.svg"
    },
    {
        "fullName": "Cuba",
        "name": "Cuba",
        "price": "0.0881",
        "code": "CU",
        "emoji": "🇨🇺",
        "unicode": "U+1F1E8 U+1F1FA",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CU.svg"
    },
    {
        "fullName": "Curacao",
        "name": "Curaçao",
        "price": "0.1956",
        "code": "CW",
        "emoji": "🇨🇼",
        "unicode": "U+1F1E8 U+1F1FC",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CW.svg"
    },
    {
        "fullName": "Cyprus",
        "name": "Cyprus",
        "price": "0.0297",
        "code": "CY",
        "emoji": "🇨🇾",
        "unicode": "U+1F1E8 U+1F1FE",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CY.svg"
    },
    {
        "fullName": "Czech Republic",
        "name": "Czechia",
        "price": "0.0554",
        "code": "CZ",
        "emoji": "🇨🇿",
        "unicode": "U+1F1E8 U+1F1FF",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CZ.svg"
    },
    {
        "fullName": "Democratic Republic of the Congo",
        "name": "Congo - Kinshasa",
        "price": "0.1902",
        "code": "CD",
        "emoji": "🇨🇩",
        "unicode": "U+1F1E8 U+1F1E9",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CD.svg"
    },
    {
        "fullName": "Denmark",
        "name": "Denmark",
        "price": "0.0561",
        "code": "DK",
        "emoji": "🇩🇰",
        "unicode": "U+1F1E9 U+1F1F0",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DK.svg"
    },
    {
        "fullName": "Diego Garcia",
        "name": "Diego Garcia",
        "price": "0.1350",
        "code": "DG",
        "emoji": "🇩🇬",
        "unicode": "U+1F1E9 U+1F1EC",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DG.svg"
    },
    {
        "fullName": "Djibouti",
        "name": "Djibouti",
        "price": "0.1537",
        "code": "DJ",
        "emoji": "🇩🇯",
        "unicode": "U+1F1E9 U+1F1EF",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DJ.svg"
    },
    {
        "fullName": "Dominica",
        "name": "Dominica",
        "price": "0.1635",
        "code": "DM",
        "emoji": "🇩🇲",
        "unicode": "U+1F1E9 U+1F1F2",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DM.svg"
    },
    {
        "fullName": "Dominican Republic",
        "name": "Dominican Republic",
        "price": "0.1001",
        "code": "DO",
        "emoji": "🇩🇴",
        "unicode": "U+1F1E9 U+1F1F4",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DO.svg"
    },
    {
        "fullName": "East Timor",
        "name": "Timor-Leste",
        "price": "0.1200",
        "code": "TL",
        "emoji": "🇹🇱",
        "unicode": "U+1F1F9 U+1F1F1",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TL.svg"
    },
    {
        "fullName": "Ecuador",
        "name": "Ecuador",
        "price": "0.1647",
        "code": "EC",
        "emoji": "🇪🇨",
        "unicode": "U+1F1EA U+1F1E8",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EC.svg"
    },
    {
        "fullName": "Egypt",
        "name": "Egypt",
        "price": "0.2112",
        "code": "EG",
        "emoji": "🇪🇬",
        "unicode": "U+1F1EA U+1F1EC",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EG.svg"
    },
    {
        "fullName": "El Salvador",
        "name": "El Salvador",
        "price": "0.1250",
        "code": "SV",
        "emoji": "🇸🇻",
        "unicode": "U+1F1F8 U+1F1FB",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SV.svg"
    },
    {
        "fullName": "Equatorial Guinea",
        "name": "Equatorial Guinea",
        "price": "0.2073",
        "code": "GQ",
        "emoji": "🇬🇶",
        "unicode": "U+1F1EC U+1F1F6",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GQ.svg"
    },
    {
        "fullName": "Eritrea",
        "name": "Eritrea",
        "price": "0.0999",
        "code": "ER",
        "emoji": "🇪🇷",
        "unicode": "U+1F1EA U+1F1F7",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ER.svg"
    },
    {
        "fullName": "Estonia",
        "name": "Estonia",
        "price": "0.0557",
        "code": "EE",
        "emoji": "🇪🇪",
        "unicode": "U+1F1EA U+1F1EA",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EE.svg"
    },
    {
        "fullName": "Ethiopia",
        "name": "Ethiopia",
        "price": "0.2477",
        "code": "ET",
        "emoji": "🇪🇹",
        "unicode": "U+1F1EA U+1F1F9",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ET.svg"
    },
    {
        "fullName": "Falkland Islands",
        "name": "Falkland Islands",
        "price": "0.1350",
        "code": "FK",
        "emoji": "🇫🇰",
        "unicode": "U+1F1EB U+1F1F0",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FK.svg"
    },
    {
        "fullName": "Faroe Islands",
        "name": "Faroe Islands",
        "price": "0.1350",
        "code": "FO",
        "emoji": "🇫🇴",
        "unicode": "U+1F1EB U+1F1F4",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FO.svg"
    },
    {
        "fullName": "Fiji",
        "name": "Fiji",
        "price": "0.1749",
        "code": "FJ",
        "emoji": "🇫🇯",
        "unicode": "U+1F1EB U+1F1EF",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FJ.svg"
    },
    {
        "fullName": "Finland",
        "name": "Finland",
        "price": "0.0771",
        "code": "FI",
        "emoji": "🇫🇮",
        "unicode": "U+1F1EB U+1F1EE",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FI.svg"
    },
    {
        "fullName": "France",
        "name": "France",
        "price": "0.0627",
        "code": "FR",
        "emoji": "🇫🇷",
        "unicode": "U+1F1EB U+1F1F7",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg"
    },
    {
        "fullName": "French Guiana",
        "name": "French Guiana",
        "price": "0.1515",
        "code": "GF",
        "emoji": "🇬🇫",
        "unicode": "U+1F1EC U+1F1EB",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GF.svg"
    },
    {
        "fullName": "French Polynesia",
        "name": "French Polynesia",
        "price": "0.1185",
        "code": "PF",
        "emoji": "🇵🇫",
        "unicode": "U+1F1F5 U+1F1EB",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PF.svg"
    },
    {
        "fullName": "Gabon",
        "name": "Gabon",
        "price": "0.2406",
        "code": "GA",
        "emoji": "🇬🇦",
        "unicode": "U+1F1EC U+1F1E6",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GA.svg"
    },
    {
        "fullName": "Gambia",
        "name": "Gambia",
        "price": "0.1349",
        "code": "GM",
        "emoji": "🇬🇲",
        "unicode": "U+1F1EC U+1F1F2",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GM.svg"
    },
    {
        "fullName": "Georgia",
        "name": "Georgia",
        "price": "0.1617",
        "code": "GE",
        "emoji": "🇬🇪",
        "unicode": "U+1F1EC U+1F1EA",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GE.svg"
    },
    {
        "fullName": "Germany",
        "name": "Germany",
        "price": "0.1154",
        "code": "DE",
        "emoji": "🇩🇪",
        "unicode": "U+1F1E9 U+1F1EA",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DE.svg"
    },
    {
        "fullName": "Ghana",
        "name": "Ghana",
        "price": "0.2390",
        "code": "GH",
        "emoji": "🇬🇭",
        "unicode": "U+1F1EC U+1F1ED",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GH.svg"
    },
    {
        "fullName": "Gibraltar",
        "name": "Gibraltar",
        "price": "0.1200",
        "code": "GI",
        "emoji": "🇬🇮",
        "unicode": "U+1F1EC U+1F1EE",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GI.svg"
    },
    {
        "fullName": "Greece",
        "name": "Greece",
        "price": "0.0638",
        "code": "GR",
        "emoji": "🇬🇷",
        "unicode": "U+1F1EC U+1F1F7",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GR.svg"
    },
    {
        "fullName": "Greenland",
        "name": "Greenland",
        "price": "0.1350",
        "code": "GL",
        "emoji": "🇬🇱",
        "unicode": "U+1F1EC U+1F1F1",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GL.svg"
    },
    {
        "fullName": "Grenada",
        "name": "Grenada",
        "price": "0.1830",
        "code": "GD",
        "emoji": "🇬🇩",
        "unicode": "U+1F1EC U+1F1E9",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GD.svg"
    },
    {
        "fullName": "Guadeloupe",
        "name": "Guadeloupe",
        "price": "0.1313",
        "code": "GP",
        "emoji": "🇬🇵",
        "unicode": "U+1F1EC U+1F1F5",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GP.svg"
    },
    {
        "fullName": "Guam",
        "name": "Guam",
        "price": "0.0050",
        "code": "GU",
        "emoji": "🇬🇺",
        "unicode": "U+1F1EC U+1F1FA",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GU.svg"
    },
    {
        "fullName": "Guatemala",
        "name": "Guatemala",
        "price": "0.1215",
        "code": "GT",
        "emoji": "🇬🇹",
        "unicode": "U+1F1EC U+1F1F9",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GT.svg"
    },
    {
        "fullName": "Guinea",
        "name": "Guinea",
        "price": "0.1949",
        "code": "GN",
        "emoji": "🇬🇳",
        "unicode": "U+1F1EC U+1F1F3",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GN.svg"
    },
    {
        "fullName": "Guinea-Bissau",
        "name": "Guinea-Bissau",
        "price": "0.1962",
        "code": "GW",
        "emoji": "🇬🇼",
        "unicode": "U+1F1EC U+1F1FC",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GW.svg"
    },
    {
        "fullName": "Guyana",
        "name": "Guyana",
        "price": "0.1515",
        "code": "GY",
        "emoji": "🇬🇾",
        "unicode": "U+1F1EC U+1F1FE",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GY.svg"
    },
    {
        "fullName": "Haiti",
        "name": "Haiti",
        "price": "0.2295",
        "code": "HT",
        "emoji": "🇭🇹",
        "unicode": "U+1F1ED U+1F1F9",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HT.svg"
    },
    {
        "fullName": "Honduras",
        "name": "Honduras",
        "price": "0.0879",
        "code": "HN",
        "emoji": "🇭🇳",
        "unicode": "U+1F1ED U+1F1F3",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HN.svg"
    },
    {
        "fullName": "Hong Kong",
        "name": "Hong Kong SAR China",
        "price": "0.0741",
        "code": "HK",
        "emoji": "🇭🇰",
        "unicode": "U+1F1ED U+1F1F0",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HK.svg"
    },
    {
        "fullName": "Hungary",
        "name": "Hungary",
        "price": "0.0735",
        "code": "HU",
        "emoji": "🇭🇺",
        "unicode": "U+1F1ED U+1F1FA",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HU.svg"
    },
    {
        "fullName": "Iceland",
        "name": "Iceland",
        "price": "0.0890",
        "code": "IS",
        "emoji": "🇮🇸",
        "unicode": "U+1F1EE U+1F1F8",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IS.svg"
    },
    {
        "fullName": "India",
        "name": "India",
        "price": "0.0819",
        "code": "IN",
        "emoji": "🇮🇳",
        "unicode": "U+1F1EE U+1F1F3",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg"
    },
    {
        "fullName": "Indonesia",
        "name": "Indonesia",
        "price": "0.4352",
        "code": "ID",
        "emoji": "🇮🇩",
        "unicode": "U+1F1EE U+1F1E9",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ID.svg"
    },
    {
        "fullName": "Iran",
        "name": "Iran",
        "price": "0.1074",
        "code": "IR",
        "emoji": "🇮🇷",
        "unicode": "U+1F1EE U+1F1F7",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IR.svg"
    },
    {
        "fullName": "Iraq",
        "name": "Iraq",
        "price": "0.2697",
        "code": "IQ",
        "emoji": "🇮🇶",
        "unicode": "U+1F1EE U+1F1F6",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IQ.svg"
    },
    {
        "fullName": "Ireland",
        "name": "Ireland",
        "price": "0.0720",
        "code": "IE",
        "emoji": "🇮🇪",
        "unicode": "U+1F1EE U+1F1EA",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IE.svg"
    },
    {
        "fullName": "Israel",
        "name": "Israel",
        "price": "0.2226",
        "code": "IL",
        "emoji": "🇮🇱",
        "unicode": "U+1F1EE U+1F1F1",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IL.svg"
    },
    {
        "fullName": "Italy",
        "name": "Italy",
        "price": "0.0573",
        "code": "IT",
        "emoji": "🇮🇹",
        "unicode": "U+1F1EE U+1F1F9",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IT.svg"
    },
    {
        "fullName": "Jamaica",
        "name": "Jamaica",
        "price": "0.1980",
        "code": "JM",
        "emoji": "🇯🇲",
        "unicode": "U+1F1EF U+1F1F2",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JM.svg"
    },
    {
        "fullName": "Japan",
        "name": "Japan",
        "price": "0.0629",
        "code": "JP",
        "emoji": "🇯🇵",
        "unicode": "U+1F1EF U+1F1F5",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JP.svg"
    },
    {
        "fullName": "Jordan",
        "name": "Jordan",
        "price": "0.2724",
        "code": "JO",
        "emoji": "🇯🇴",
        "unicode": "U+1F1EF U+1F1F4",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JO.svg"
    },
    {
        "fullName": "Kazakhstan",
        "name": "Kazakhstan",
        "price": "0.2546",
        "code": "KZ",
        "emoji": "🇰🇿",
        "unicode": "U+1F1F0 U+1F1FF",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KZ.svg"
    },
    {
        "fullName": "Kenya",
        "name": "Kenya",
        "price": "0.1065",
        "code": "KE",
        "emoji": "🇰🇪",
        "unicode": "U+1F1F0 U+1F1EA",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KE.svg"
    },
    {
        "fullName": "Kiribati",
        "name": "Kiribati",
        "price": "0.1763",
        "code": "KI",
        "emoji": "🇰🇮",
        "unicode": "U+1F1F0 U+1F1EE",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KI.svg"
    },
    {
        "fullName": "Kuwait",
        "name": "Kuwait",
        "price": "0.2310",
        "code": "KW",
        "emoji": "🇰🇼",
        "unicode": "U+1F1F0 U+1F1FC",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KW.svg"
    },
    {
        "fullName": "Kyrgyzstan",
        "name": "Kyrgyzstan",
        "price": "0.3132",
        "code": "KG",
        "emoji": "🇰🇬",
        "unicode": "U+1F1F0 U+1F1EC",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KG.svg"
    },
    {
        "fullName": "Lao",
        "name": "Laos",
        "price": "0.0767",
        "code": "LA",
        "emoji": "🇱🇦",
        "unicode": "U+1F1F1 U+1F1E6",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LA.svg"
    },
    {
        "fullName": "Latvia",
        "name": "Latvia",
        "price": "0.0636",
        "code": "LV",
        "emoji": "🇱🇻",
        "unicode": "U+1F1F1 U+1F1FB",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LV.svg"
    },
    {
        "fullName": "Lebanon",
        "name": "Lebanon",
        "price": "0.3264",
        "code": "LB",
        "emoji": "🇱🇧",
        "unicode": "U+1F1F1 U+1F1E7",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LB.svg"
    },
    {
        "fullName": "Lesotho",
        "name": "Lesotho",
        "price": "0.2936",
        "code": "LS",
        "emoji": "🇱🇸",
        "unicode": "U+1F1F1 U+1F1F8",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LS.svg"
    },
    {
        "fullName": "Liberia",
        "name": "Liberia",
        "price": "0.1643",
        "code": "LR",
        "emoji": "🇱🇷",
        "unicode": "U+1F1F1 U+1F1F7",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LR.svg"
    },
    {
        "fullName": "Libya",
        "name": "Libya",
        "price": "0.3729",
        "code": "LY",
        "emoji": "🇱🇾",
        "unicode": "U+1F1F1 U+1F1FE",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LY.svg"
    },
    {
        "fullName": "Liechtenstein",
        "name": "Liechtenstein",
        "price": "0.1350",
        "code": "LI",
        "emoji": "🇱🇮",
        "unicode": "U+1F1F1 U+1F1EE",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LI.svg"
    },
    {
        "fullName": "Lithuania",
        "name": "Lithuania",
        "price": "0.0509",
        "code": "LT",
        "emoji": "🇱🇹",
        "unicode": "U+1F1F1 U+1F1F9",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LT.svg"
    },
    {
        "fullName": "Luxembourg",
        "name": "Luxembourg",
        "price": "0.0722",
        "code": "LU",
        "emoji": "🇱🇺",
        "unicode": "U+1F1F1 U+1F1FA",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LU.svg"
    },
    {
        "fullName": "Macao",
        "name": "Macao SAR China",
        "price": "0.1350",
        "code": "MO",
        "emoji": "🇲🇴",
        "unicode": "U+1F1F2 U+1F1F4",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MO.svg"
    },
    {
        "fullName": "Macedonia",
        "name": "North Macedonia",
        "price": "0.0203",
        "code": "MK",
        "emoji": "🇲🇰",
        "unicode": "U+1F1F2 U+1F1F0",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MK.svg"
    },
    {
        "fullName": "Madagascar",
        "name": "Madagascar",
        "price": "0.4074",
        "code": "MG",
        "emoji": "🇲🇬",
        "unicode": "U+1F1F2 U+1F1EC",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MG.svg"
    },
    {
        "fullName": "Malawi",
        "name": "Malawi",
        "price": "0.2735",
        "code": "MW",
        "emoji": "🇲🇼",
        "unicode": "U+1F1F2 U+1F1FC",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MW.svg"
    },
    {
        "fullName": "Malaysia",
        "name": "Malaysia",
        "price": "0.0675",
        "code": "MY",
        "emoji": "🇲🇾",
        "unicode": "U+1F1F2 U+1F1FE",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MY.svg"
    },
    {
        "fullName": "Maldives",
        "name": "Maldives",
        "price": "0.0806",
        "code": "MV",
        "emoji": "🇲🇻",
        "unicode": "U+1F1F2 U+1F1FB",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MV.svg"
    },
    {
        "fullName": "Mali",
        "name": "Mali",
        "price": "0.1752",
        "code": "ML",
        "emoji": "🇲🇱",
        "unicode": "U+1F1F2 U+1F1F1",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ML.svg"
    },
    {
        "fullName": "Malta",
        "name": "Malta",
        "price": "0.0752",
        "code": "MT",
        "emoji": "🇲🇹",
        "unicode": "U+1F1F2 U+1F1F9",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MT.svg"
    },
    {
        "fullName": "Marshall Islands",
        "name": "Marshall Islands",
        "price": "0.0521",
        "code": "MH",
        "emoji": "🇲🇭",
        "unicode": "U+1F1F2 U+1F1ED",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MH.svg"
    },
    {
        "fullName": "Martinique",
        "name": "Martinique",
        "price": "0.1494",
        "code": "MQ",
        "emoji": "🇲🇶",
        "unicode": "U+1F1F2 U+1F1F6",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MQ.svg"
    },
    {
        "fullName": "Mauritania",
        "name": "Mauritania",
        "price": "0.2028",
        "code": "MR",
        "emoji": "🇲🇷",
        "unicode": "U+1F1F2 U+1F1F7",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MR.svg"
    },
    {
        "fullName": "Mauritius",
        "name": "Mauritius",
        "price": "0.1278",
        "code": "MU",
        "emoji": "🇲🇺",
        "unicode": "U+1F1F2 U+1F1FA",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MU.svg"
    },
    {
        "fullName": "Mayotte",
        "name": "Mayotte",
        "price": "0.2378",
        "code": "YT",
        "emoji": "🇾🇹",
        "unicode": "U+1F1FE U+1F1F9",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YT.svg"
    },
    {
        "fullName": "Mexico",
        "name": "Mexico",
        "price": "0.0582",
        "code": "MX",
        "emoji": "🇲🇽",
        "unicode": "U+1F1F2 U+1F1FD",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MX.svg"
    },
    {
        "fullName": "Micronesia",
        "name": "Micronesia",
        "price": "0.1350",
        "code": "FM",
        "emoji": "🇫🇲",
        "unicode": "U+1F1EB U+1F1F2",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FM.svg"
    },
    {
        "fullName": "Moldova",
        "name": "Moldova",
        "price": "0.0656",
        "code": "MD",
        "emoji": "🇲🇩",
        "unicode": "U+1F1F2 U+1F1E9",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MD.svg"
    },
    {
        "fullName": "Monaco",
        "name": "Monaco",
        "price": "0.1277",
        "code": "MC",
        "emoji": "🇲🇨",
        "unicode": "U+1F1F2 U+1F1E8",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MC.svg"
    },
    {
        "fullName": "Mongolia",
        "name": "Mongolia",
        "price": "0.2634",
        "code": "MN",
        "emoji": "🇲🇳",
        "unicode": "U+1F1F2 U+1F1F3",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MN.svg"
    },
    {
        "fullName": "Montenegro",
        "name": "Montenegro",
        "price": "0.1112",
        "code": "ME",
        "emoji": "🇲🇪",
        "unicode": "U+1F1F2 U+1F1EA",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ME.svg"
    },
    {
        "fullName": "Montserrat",
        "name": "Montserrat",
        "price": "0.1350",
        "code": "MS",
        "emoji": "🇲🇸",
        "unicode": "U+1F1F2 U+1F1F8",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MS.svg"
    },
    {
        "fullName": "Morocco",
        "name": "Morocco",
        "price": "0.2099",
        "code": "MA",
        "emoji": "🇲🇦",
        "unicode": "U+1F1F2 U+1F1E6",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MA.svg"
    },
    {
        "fullName": "Mozambique",
        "name": "Mozambique",
        "price": "0.1682",
        "code": "MZ",
        "emoji": "🇲🇿",
        "unicode": "U+1F1F2 U+1F1FF",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MZ.svg"
    },
    {
        "fullName": "Myanmar",
        "name": "Myanmar (Burma)",
        "price": "0.2480",
        "code": "MM",
        "emoji": "🇲🇲",
        "unicode": "U+1F1F2 U+1F1F2",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MM.svg"
    },
    {
        "fullName": "Namibia",
        "name": "Namibia",
        "price": "0.0401",
        "code": "NA",
        "emoji": "🇳🇦",
        "unicode": "U+1F1F3 U+1F1E6",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NA.svg"
    },
    {
        "fullName": "Nauru",
        "name": "Nauru",
        "price": "0.2472",
        "code": "NR",
        "emoji": "🇳🇷",
        "unicode": "U+1F1F3 U+1F1F7",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NR.svg"
    },
    {
        "fullName": "Nepal",
        "name": "Nepal",
        "price": "0.3077",
        "code": "NP",
        "emoji": "🇳🇵",
        "unicode": "U+1F1F3 U+1F1F5",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NP.svg"
    },
    {
        "fullName": "Netherlands",
        "name": "Netherlands",
        "price": "0.1073",
        "code": "NL",
        "emoji": "🇳🇱",
        "unicode": "U+1F1F3 U+1F1F1",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NL.svg"
    },
    {
        "fullName": "New Caledonia",
        "name": "New Caledonia",
        "price": "0.1029",
        "code": "NC",
        "emoji": "🇳🇨",
        "unicode": "U+1F1F3 U+1F1E8",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NC.svg"
    },
    {
        "fullName": "New Zealand",
        "name": "New Zealand",
        "price": "0.1070",
        "code": "NZ",
        "emoji": "🇳🇿",
        "unicode": "U+1F1F3 U+1F1FF",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NZ.svg"
    },
    {
        "fullName": "Nicaragua",
        "name": "Nicaragua",
        "price": "0.1007",
        "code": "NI",
        "emoji": "🇳🇮",
        "unicode": "U+1F1F3 U+1F1EE",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NI.svg"
    },
    {
        "fullName": "Niger",
        "name": "Niger",
        "price": "0.3042",
        "code": "NE",
        "emoji": "🇳🇪",
        "unicode": "U+1F1F3 U+1F1EA",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NE.svg"
    },
    {
        "fullName": "Nigeria",
        "name": "Nigeria",
        "price": "0.2418",
        "code": "NG",
        "emoji": "🇳🇬",
        "unicode": "U+1F1F3 U+1F1EC",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NG.svg"
    },
    {
        "fullName": "Niue",
        "name": "Niue",
        "price": "0.2303",
        "code": "NU",
        "emoji": "🇳🇺",
        "unicode": "U+1F1F3 U+1F1FA",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NU.svg"
    },
    {
        "fullName": "Norfolk Island",
        "name": "Norfolk Island",
        "price": "0.1350",
        "code": "NF",
        "emoji": "🇳🇫",
        "unicode": "U+1F1F3 U+1F1EB",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NF.svg"
    },
    {
        "fullName": "Northern Mariana Islands",
        "name": "Northern Mariana Islands",
        "price": "0.1350",
        "code": "MP",
        "emoji": "🇲🇵",
        "unicode": "U+1F1F2 U+1F1F5",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MP.svg"
    },
    {
        "fullName": "Norway",
        "name": "Norway",
        "price": "0.0645",
        "code": "NO",
        "emoji": "🇳🇴",
        "unicode": "U+1F1F3 U+1F1F4",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NO.svg"
    },
    {
        "fullName": "Oman",
        "name": "Oman",
        "price": "0.1094",
        "code": "OM",
        "emoji": "🇴🇲",
        "unicode": "U+1F1F4 U+1F1F2",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/OM.svg"
    },
    {
        "fullName": "Pakistan",
        "name": "Pakistan",
        "price": "0.2978",
        "code": "PK",
        "emoji": "🇵🇰",
        "unicode": "U+1F1F5 U+1F1F0",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PK.svg"
    },
    {
        "fullName": "Palau",
        "name": "Palau",
        "price": "0.1350",
        "code": "PW",
        "emoji": "🇵🇼",
        "unicode": "U+1F1F5 U+1F1FC",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PW.svg"
    },
    {
        "fullName": "Palestine",
        "name": "Palestinian Territories",
        "price": "0.3671",
        "code": "PS",
        "emoji": "🇵🇸",
        "unicode": "U+1F1F5 U+1F1F8",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PS.svg"
    },
    {
        "fullName": "Panama",
        "name": "Panama",
        "price": "0.1188",
        "code": "PA",
        "emoji": "🇵🇦",
        "unicode": "U+1F1F5 U+1F1E6",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PA.svg"
    },
    {
        "fullName": "Papua New Guinea",
        "name": "Papua New Guinea",
        "price": "0.2355",
        "code": "PG",
        "emoji": "🇵🇬",
        "unicode": "U+1F1F5 U+1F1EC",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PG.svg"
    },
    {
        "fullName": "Paraguay",
        "name": "Paraguay",
        "price": "0.0762",
        "code": "PY",
        "emoji": "🇵🇾",
        "unicode": "U+1F1F5 U+1F1FE",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PY.svg"
    },
    {
        "fullName": "Peru",
        "name": "Peru",
        "price": "0.1152",
        "code": "PE",
        "emoji": "🇵🇪",
        "unicode": "U+1F1F5 U+1F1EA",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PE.svg"
    },
    {
        "fullName": "Philippines",
        "name": "Philippines",
        "price": "0.2015",
        "code": "PH",
        "emoji": "🇵🇭",
        "unicode": "U+1F1F5 U+1F1ED",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PH.svg"
    },
    {
        "fullName": "Poland",
        "name": "Poland",
        "price": "0.0222",
        "code": "PL",
        "emoji": "🇵🇱",
        "unicode": "U+1F1F5 U+1F1F1",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PL.svg"
    },
    {
        "fullName": "Portugal",
        "name": "Portugal",
        "price": "0.0195",
        "code": "PT",
        "emoji": "🇵🇹",
        "unicode": "U+1F1F5 U+1F1F9",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PT.svg"
    },
    {
        "fullName": "Puerto Rico",
        "name": "Puerto Rico",
        "price": "0.150",
        "code": "PR",
        "emoji": "🇵🇷",
        "unicode": "U+1F1F5 U+1F1F7",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PR.svg"
    },
    {
        "fullName": "Qatar",
        "name": "Qatar",
        "price": "0.2292",
        "code": "QA",
        "emoji": "🇶🇦",
        "unicode": "U+1F1F6 U+1F1E6",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/QA.svg"
    },
    {
        "fullName": "Reunion",
        "name": "Réunion",
        "price": "0.1350",
        "code": "RE",
        "emoji": "🇷🇪",
        "unicode": "U+1F1F7 U+1F1EA",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RE.svg"
    },
    {
        "fullName": "Romania",
        "name": "Romania",
        "price": "0.0512",
        "code": "RO",
        "emoji": "🇷🇴",
        "unicode": "U+1F1F7 U+1F1F4",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RO.svg"
    },
    {
        "fullName": "Russia",
        "name": "Russia",
        "price": "0.2940",
        "code": "RU",
        "emoji": "🇷🇺",
        "unicode": "U+1F1F7 U+1F1FA",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RU.svg"
    },
    {
        "fullName": "Rwanda",
        "name": "Rwanda",
        "price": "0.1790",
        "code": "RW",
        "emoji": "🇷🇼",
        "unicode": "U+1F1F7 U+1F1FC",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RW.svg"
    },
    {
        "fullName": "Saint Helena",
        "name": "St. Helena",
        "price": "0.1350",
        "code": "SH",
        "emoji": "🇸🇭",
        "unicode": "U+1F1F8 U+1F1ED",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SH.svg"
    },
    {
        "fullName": "Saint Kitts and Nevis",
        "name": "St. Kitts & Nevis",
        "price": "0.1539",
        "code": "KN",
        "emoji": "🇰🇳",
        "unicode": "U+1F1F0 U+1F1F3",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KN.svg"
    },
    {
        "fullName": "Saint Lucia",
        "name": "St. Lucia",
        "price": "0.1956",
        "code": "LC",
        "emoji": "🇱🇨",
        "unicode": "U+1F1F1 U+1F1E8",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LC.svg"
    },
    {
        "fullName": "Saint Pierre and Miquelon",
        "name": "St. Pierre & Miquelon",
        "price": "0.1350",
        "code": "PM",
        "emoji": "🇵🇲",
        "unicode": "U+1F1F5 U+1F1F2",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PM.svg"
    },
    {
        "fullName": "Saint Vincent and the Grenadines",
        "name": "St. Vincent & Grenadines",
        "price": "0.1740",
        "code": "VC",
        "emoji": "🇻🇨",
        "unicode": "U+1F1FB U+1F1E8",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VC.svg"
    },
    {
        "fullName": "Samoa",
        "name": "Samoa",
        "price": "0.2022",
        "code": "WS",
        "emoji": "🇼🇸",
        "unicode": "U+1F1FC U+1F1F8",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WS.svg"
    },
    {
        "fullName": "San Marino",
        "name": "San Marino",
        "price": "0.1350",
        "code": "SM",
        "emoji": "🇸🇲",
        "unicode": "U+1F1F8 U+1F1F2",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SM.svg"
    },
    {
        "fullName": "Sao Tome and Principe",
        "name": "São Tomé & Príncipe",
        "price": "0.1350",
        "code": "ST",
        "emoji": "🇸🇹",
        "unicode": "U+1F1F8 U+1F1F9",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ST.svg"
    },
    {
        "fullName": "Saudi Arabia",
        "name": "Saudi Arabia",
        "price": "0.1193",
        "code": "SA",
        "emoji": "🇸🇦",
        "unicode": "U+1F1F8 U+1F1E6",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SA.svg"
    },
    {
        "fullName": "Senegal",
        "name": "Senegal",
        "price": "0.1878",
        "code": "SN",
        "emoji": "🇸🇳",
        "unicode": "U+1F1F8 U+1F1F3",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SN.svg"
    },
    {
        "fullName": "Serbia",
        "name": "Serbia",
        "price": "0.3132",
        "code": "RS",
        "emoji": "🇷🇸",
        "unicode": "U+1F1F7 U+1F1F8",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RS.svg"
    },
    {
        "fullName": "Seychelles",
        "name": "Seychelles",
        "price": "0.1350",
        "code": "SC",
        "emoji": "🇸🇨",
        "unicode": "U+1F1F8 U+1F1E8",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SC.svg"
    },
    {
        "fullName": "Sierra Leone",
        "name": "Sierra Leone",
        "price": "0.2510",
        "code": "SL",
        "emoji": "🇸🇱",
        "unicode": "U+1F1F8 U+1F1F1",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SL.svg"
    },
    {
        "fullName": "Singapore",
        "name": "Singapore",
        "price": "0.0479",
        "code": "SG",
        "emoji": "🇸🇬",
        "unicode": "U+1F1F8 U+1F1EC",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SG.svg"
    },
    {
        "fullName": "Sint Maarten",
        "name": "Sint Maarten",
        "price": "0.1350",
        "code": "SX",
        "emoji": "🇸🇽",
        "unicode": "U+1F1F8 U+1F1FD",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SX.svg"
    },
    {
        "fullName": "Slovakia",
        "name": "Slovakia",
        "price": "0.0582",
        "code": "SK",
        "emoji": "🇸🇰",
        "unicode": "U+1F1F8 U+1F1F0",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SK.svg"
    },
    {
        "fullName": "Slovenia",
        "name": "Slovenia",
        "price": "0.1385",
        "code": "SI",
        "emoji": "🇸🇮",
        "unicode": "U+1F1F8 U+1F1EE",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SI.svg"
    },
    {
        "fullName": "Solomon Islands",
        "name": "Solomon Islands",
        "price": "0.1350",
        "code": "SB",
        "emoji": "🇸🇧",
        "unicode": "U+1F1F8 U+1F1E7",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SB.svg"
    },
    {
        "fullName": "Somalia",
        "name": "Somalia",
        "price": "0.2399",
        "code": "SO",
        "emoji": "🇸🇴",
        "unicode": "U+1F1F8 U+1F1F4",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SO.svg"
    },
    {
        "fullName": "South Africa",
        "name": "South Africa",
        "price": "0.0617",
        "code": "ZA",
        "emoji": "🇿🇦",
        "unicode": "U+1F1FF U+1F1E6",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZA.svg"
    },
    {
        "fullName": "South Korea",
        "name": "South Korea",
        "price": "0.0150",
        "code": "KR",
        "emoji": "🇰🇷",
        "unicode": "U+1F1F0 U+1F1F7",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KR.svg"
    },
    {
        "fullName": "South Sudan",
        "name": "South Sudan",
        "price": "0.1737",
        "code": "SS",
        "emoji": "🇸🇸",
        "unicode": "U+1F1F8 U+1F1F8",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SS.svg"
    },
    {
        "fullName": "Spain",
        "name": "Spain",
        "price": "0.0459",
        "code": "ES",
        "emoji": "🇪🇸",
        "unicode": "U+1F1EA U+1F1F8",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg"
    },
    {
        "fullName": "Sri Lanka",
        "name": "Sri Lanka",
        "price": "0.3035",
        "code": "LK",
        "emoji": "🇱🇰",
        "unicode": "U+1F1F1 U+1F1F0",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LK.svg"
    },
    {
        "fullName": "Sudan",
        "name": "Sudan",
        "price": "0.3161",
        "code": "SD",
        "emoji": "🇸🇩",
        "unicode": "U+1F1F8 U+1F1E9",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SD.svg"
    },
    {
        "fullName": "Suriname",
        "name": "Suriname",
        "price": "0.1245",
        "code": "SR",
        "emoji": "🇸🇷",
        "unicode": "U+1F1F8 U+1F1F7",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SR.svg"
    },
    {
        "fullName": "Swaziland",
        "name": "Eswatini",
        "price": "0.1350",
        "code": "SZ",
        "emoji": "🇸🇿",
        "unicode": "U+1F1F8 U+1F1FF",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SZ.svg"
    },
    {
        "fullName": "Sweden",
        "name": "Sweden",
        "price": "0.0446",
        "code": "SE",
        "emoji": "🇸🇪",
        "unicode": "U+1F1F8 U+1F1EA",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SE.svg"
    },
    {
        "fullName": "Switzerland",
        "name": "Switzerland",
        "price": "0.0306",
        "code": "CH",
        "emoji": "🇨🇭",
        "unicode": "U+1F1E8 U+1F1ED",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CH.svg"
    },
    {
        "fullName": "Syria",
        "name": "Syria",
        "price": "0.3282",
        "code": "SY",
        "emoji": "🇸🇾",
        "unicode": "U+1F1F8 U+1F1FE",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SY.svg"
    },
    {
        "fullName": "Taiwan",
        "name": "Taiwan",
        "price": "0.0773",
        "code": "TW",
        "emoji": "🇹🇼",
        "unicode": "U+1F1F9 U+1F1FC",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TW.svg"
    },
    {
        "fullName": "Tajikistan",
        "name": "Tajikistan",
        "price": "0.4428",
        "code": "TJ",
        "emoji": "🇹🇯",
        "unicode": "U+1F1F9 U+1F1EF",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TJ.svg"
    },
    {
        "fullName": "Tanzania",
        "name": "Tanzania",
        "price": "0.2378",
        "code": "TZ",
        "emoji": "🇹🇿",
        "unicode": "U+1F1F9 U+1F1FF",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TZ.svg"
    },
    {
        "fullName": "Thailand",
        "name": "Thailand",
        "price": "0.0099",
        "code": "TH",
        "emoji": "🇹🇭",
        "unicode": "U+1F1F9 U+1F1ED",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TH.svg"
    },
    {
        "fullName": "Togo",
        "name": "Togo",
        "price": "0.2636",
        "code": "TG",
        "emoji": "🇹🇬",
        "unicode": "U+1F1F9 U+1F1EC",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TG.svg"
    },
    {
        "fullName": "Tokelau",
        "name": "Tokelau",
        "price": "0.1350",
        "code": "TK",
        "emoji": "🇹🇰",
        "unicode": "U+1F1F9 U+1F1F0",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TK.svg"
    },
    {
        "fullName": "Tonga",
        "name": "Tonga",
        "price": "0.1350",
        "code": "TO",
        "emoji": "🇹🇴",
        "unicode": "U+1F1F9 U+1F1F4",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TO.svg"
    },
    {
        "fullName": "Trinidad and Tobago",
        "name": "Trinidad & Tobago",
        "price": "0.2054",
        "code": "TT",
        "emoji": "🇹🇹",
        "unicode": "U+1F1F9 U+1F1F9",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TT.svg"
    },
    {
        "fullName": "Tunisia",
        "name": "Tunisia",
        "price": "0.2918",
        "code": "TN",
        "emoji": "🇹🇳",
        "unicode": "U+1F1F9 U+1F1F3",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TN.svg"
    },
    {
        "fullName": "Turkey",
        "name": "Turkey",
        "price": "0.0036",
        "code": "TR",
        "emoji": "🇹🇷",
        "unicode": "U+1F1F9 U+1F1F7",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TR.svg"
    },
    {
        "fullName": "Turkmenistan",
        "name": "Turkmenistan",
        "price": "0.0026",
        "code": "TM",
        "emoji": "🇹🇲",
        "unicode": "U+1F1F9 U+1F1F2",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TM.svg"
    },
    {
        "fullName": "Turks and Caicos Islands",
        "name": "Turks & Caicos Islands",
        "price": "0.1856",
        "code": "TC",
        "emoji": "🇹🇨",
        "unicode": "U+1F1F9 U+1F1E8",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TC.svg"
    },
    {
        "fullName": "Tuvalu",
        "name": "Tuvalu",
        "price": "0.1350",
        "code": "TV",
        "emoji": "🇹🇻",
        "unicode": "U+1F1F9 U+1F1FB",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TV.svg"
    },
    {
        "fullName": "U.S. Virgin Islands",
        "name": "U.S. Virgin Islands",
        "price": "0.1350",
        "code": "VI",
        "emoji": "🇻🇮",
        "unicode": "U+1F1FB U+1F1EE",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VI.svg"
    },
    {
        "fullName": "Uganda",
        "name": "Uganda",
        "price": "0.2363",
        "code": "UG",
        "emoji": "🇺🇬",
        "unicode": "U+1F1FA U+1F1EC",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UG.svg"
    },
    {
        "fullName": "Ukraine",
        "name": "Ukraine",
        "price": "0.1670",
        "code": "UA",
        "emoji": "🇺🇦",
        "unicode": "U+1F1FA U+1F1E6",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UA.svg"
    },
    {
        "fullName": "United Arab Emirates",
        "name": "United Arab Emirates",
        "price": "0.1163",
        "code": "AE",
        "emoji": "🇦🇪",
        "unicode": "U+1F1E6 U+1F1EA",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AE.svg"
    },
    {
        "fullName": "United Kingdom",
        "name": "United Kingdom",
        "price": "0.0525",
        "code": "GB",
        "emoji": "🇬🇧",
        "unicode": "U+1F1EC U+1F1E7",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg"
    },
    {
        "fullName": "United States",
        "name": "United States",
        "price": "0.0117",
        "code": "US",
        "emoji": "🇺🇸",
        "unicode": "U+1F1FA U+1F1F8",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg"
    },
    {
        "fullName": "Uruguay",
        "name": "Uruguay",
        "price": "0.0441",
        "code": "UY",
        "emoji": "🇺🇾",
        "unicode": "U+1F1FA U+1F1FE",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UY.svg"
    },
    {
        "fullName": "Uzbekistan",
        "name": "Uzbekistan",
        "price": "0.3333",
        "code": "UZ",
        "emoji": "🇺🇿",
        "unicode": "U+1F1FA U+1F1FF",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UZ.svg"
    },
    {
        "fullName": "Vanuatu",
        "name": "Vanuatu",
        "price": "0.1532",
        "code": "VU",
        "emoji": "🇻🇺",
        "unicode": "U+1F1FB U+1F1FA",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VU.svg"
    },
    {
        "fullName": "Vatican City State",
        "name": "Vatican City",
        "price": "0.1350",
        "code": "VA",
        "emoji": "🇻🇦",
        "unicode": "U+1F1FB U+1F1E6",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VA.svg"
    },
    {
        "fullName": "Venezuela",
        "name": "Venezuela",
        "price": "0.1170",
        "code": "VE",
        "emoji": "🇻🇪",
        "unicode": "U+1F1FB U+1F1EA",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VE.svg"
    },
    {
        "fullName": "Vietnam",
        "name": "Vietnam",
        "price": "0.1497",
        "code": "VN",
        "emoji": "🇻🇳",
        "unicode": "U+1F1FB U+1F1F3",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VN.svg"
    },
    {
        "fullName": "Wallis and Futuna",
        "name": "Wallis & Futuna",
        "price": "0.1200",
        "code": "WF",
        "emoji": "🇼🇫",
        "unicode": "U+1F1FC U+1F1EB",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WF.svg"
    },
    {
        "fullName": "Yemen",
        "name": "Yemen",
        "price": "0.2583",
        "code": "YE",
        "emoji": "🇾🇪",
        "unicode": "U+1F1FE U+1F1EA",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YE.svg"
    },
    {
        "fullName": "Zambia",
        "name": "Zambia",
        "price": "0.2583",
        "code": "ZM",
        "emoji": "🇿🇲",
        "unicode": "U+1F1FF U+1F1F2",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZM.svg"
    },
    {
        "fullName": "Zimbabwe",
        "name": "Zimbabwe",
        "price": "0.2196",
        "code": "ZW",
        "emoji": "🇿🇼",
        "unicode": "U+1F1FF U+1F1FC",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZW.svg"
    }
]
