import React, {useState} from 'react';
import {PRICE_DEFAULT} from '../../../constant/config';

function Pricing() {
    const [getPriceCountry, setGetpriceCountry] = useState(0.0117);
    const handleGetPriceOfcountry = (e) => {
        const index = e.target.selectedIndex;
        const el = e.target.childNodes[index]
        const option = el.getAttribute('id');
        setGetpriceCountry(option)
    }
    return (
        <div className='pr__wrapper_all'>
            <p className='pr__title_of_page'>
                Pay-as-you-go SMS
            </p>
            <p className='pr__title_of_page_desc'>
                Get the
                <span className='pr__title_of_page_desc_blue'> best SMS price in town </span>
                with simple plans.
                <span className='pr__title_of_page_desc_yellow'> No additional fee.</span>
            </p>

            <div className='pr__price_of_message'>
                <p className='pr__price_of_message_title'>
                    SEND MESSAGES IN
                </p>
                <select onChange={handleGetPriceOfcountry}
                    className='pr__price_of_message_select'>
                    {
                    PRICE_DEFAULT.map((item, index) => <option key={index}
                        value={
                            item.name
                        }
                        id={
                            item.price
                    }>
                        <div>
                            {item.emoji}
                        </div>
                        &emsp;{item.fullName}
                    </option>)
                } </select>
                <div className='pr__price_of_message_table'>
                    <div className='pr__price_of_message_table_title'>
                        <p>
                            FREE PLAN
                        </p>
                        <p>
                            PREMIUM PLAN <br />
                            <span>For merchants send 1,000+ SMS/month</span>
                        </p>
                    </div>
                    <div className='pr__price_of_message_table_body'>
                        <p>
                            ${(getPriceCountry * 1.8).toFixed(4)}
                        </p>
                        <p>
                            ${getPriceCountry}
                        </p>
                    </div>
                </div>

                <div className='pr__price_of_message_table_bellow'>
                    <p>*Text messages are charged per SMS</p>
                    <p>*Price may vary based on the carrier to which the SMS is being sent.</p>
                </div>
            </div>
        </div>
    )
}

export default Pricing
