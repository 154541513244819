import React from "react";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import './assets/scss/Global.scss';
import MainOutside from './components/MainOutside';
import Notfound from './components/Notfound';
import Dashboard from './components/Dashboard/Dashboard';

function App() {
    return (
        <Router>
            <Routes>
                <Route path='/'
                    element={<MainOutside/>}/>
                <Route path='*'
                    element={<Notfound/>}/>
                <Route path='/dashboard/*'
                    element={<Dashboard/>}/>
            </Routes>
        </Router>
    );
}

export default App;
