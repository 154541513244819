import React from 'react';
import imgageWrapper from '../assets/image/message/welcome1.webp'
import Message from './Message';

function MainOutside() {
    return (
        <div className='main_outside_wrapper'>
            <img className='main_outside_image' src={imgageWrapper} alt="" />
            <Message />
        </div>
    )
}

export default MainOutside
