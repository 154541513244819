import React, { useEffect } from 'react'
import moneyBag from '../../../assets/image/dashboard/dashboardBody/moneybag.svg'
import image_1 from '../../../assets/image/dashboard/dashboardBody/image_dashboard_left.webp'
import image_2 from '../../../assets/image/dashboard/dashboardBody/image_dashboard_right.webp'
import image_3 from '../../../assets/image/dashboard/dashboardBody/image_dashboard_bottom.webp'
import logo1 from '../../../assets/image/dashboard/dashboardBody/fiverr.svg'
import logo2 from '../../../assets/image/dashboard/dashboardBody/byteDance.svg'
import logo3 from '../../../assets/image/dashboard/dashboardBody/salesforce.svg'
import logo4 from '../../../assets/image/dashboard/dashboardBody/skype.svg'
import count from '../../../assets/image/dashboard/dashboardBody/count.webp'
import lightBulb from '../../../assets/image/dashboard/dashboardBody/LightBulb.svg'
import { gsap } from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger.js"
import gifCard_1 from '../../../assets/image/dashboard/gif/collect_more_fan.gif';
import gifCard_2 from '../../../assets/image/dashboard/gif/Gif2.gif';
import gifCard_3 from '../../../assets/image/dashboard/gif/GIF3.gif';
import coffee_image from '../../../assets/image/dashboard/dashboardBody/paper coffee cup standing.svg';
import phone_sent from '../../../assets/image/dashboard/dashboardBody/phone_sent.gif';

 
function DashboardBody({setStateUpdate}) {
    const animateCount = (obj, start, end, duration, type) => {
        let startTimestamp = null;
        const step = (timestamp) => {
          if (!startTimestamp) startTimestamp = timestamp;
          const progress = Math.min((timestamp - startTimestamp) / duration, 1);
          obj.innerHTML = `${Math.floor(progress * (end - start) + start)}${type === 'percent' ? '%' : 'min'} ` ;
          if (progress < 1) {
            window.requestAnimationFrame(step);
          }
        };
        window.requestAnimationFrame(step);
    }

    useEffect(() => {
        const handleScroll = () => {
            const position = window.pageYOffset;
            let percent = document.querySelector('#percent')
            let min = document.querySelector('#min')
            let lastPercent = document.querySelector('#lastPercent')
            if (position > 1400) {
                animateCount(percent, 0, 95, 3000, 'percent')
                animateCount(min, 0, 3, 3000, 'min')
                animateCount(lastPercent, 0, 90, 3000, 'percent')
            }
        };

        window.onscroll = myScroll;
            var counter = 0; 
            var counterFooter = 0; 
            function myScroll(){
            if(counter === 0){ 
                if(window.pageYOffset > 1400){
                    handleScroll()
                    counter++; 
                }  
            } else if (counterFooter === 0) {
                if (window.pageYOffset > 4814) {
                    setStateUpdate(true);
                    counterFooter++
                }
            }
        }
    }, []);
    
    useEffect(() => {
        if (typeof document !== 'undefined') {
            document.addEventListener('mouseout', parallax);
            function parallax(e) {
                document.querySelectorAll('.so__landingpage_item_effect_image').forEach(function (move) {
                    const moving_value = move.getAttribute('data-value');
                    const x = (e.clientX * moving_value) / 250 ;
                    const y = (e.clientY * moving_value) / 250 ;

                    move.style.transform = "translateX(" + x + "px) translateY(" + y + "px)";
                    move.style.transition = `all 0.7s ease`
                })
            }
        }
    }, [])

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        const tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".so__landingpage_item_world_cardboard",
            pin: true,
            start: "top 10%",
            end: "bottom+=1000 bottom",
            scrub: true
        }
        });

        tl.to("#card1__dashboard", {
        y: "-90vh"
        }).to(
        "#card2__dashboard",
        {
            y: "-85vh"
        },
        "-=0.15"
        );
    }, [])

    useEffect(() => {
        const cursor = document.getElementById("cursor_custom");
        const doc = document.querySelector('#so__landingpage_item_effect')
        doc.addEventListener('mousemove', function(e){
            cursor.style.top = (e.clientY - 100) + 'px';
            cursor.style.left = (e.clientX - 100) + 'px';
            cursor.style.transition =  `all 0.3s ease`;
        });

    }, [])

    return (
        <div className='so__landingpage_wrapper'>
            <div id="so__landingpage_item_effect" className='so__landingpage_item_effect'>
                <p className='so__landingpage_item_effect_title'>
                    Revenue & engagement <br /> revolution
                </p>
                <p className='so__landingpage_item_effect_title_desc'>Platform <span>100% built for e-com</span> that turns SMS into <span>cha-ching</span> <img src={moneyBag} alt=""></img></p>
                <div id="cursor_custom" className="cursor_custom"></div>
                <img className='so__landingpage_item_effect_image so__landingpage_item_effect_image_1' data-value="9" src={image_1} alt="" />
                <img className='so__landingpage_item_effect_image so__landingpage_item_effect_image_2' data-value="5"  src={image_2} alt="" />
                <img className='so__landingpage_item_effect_image so__landingpage_item_effect_image_3' data-value="8"  src={image_3} alt="" />
                <div className='so__landingpage_item_effect_logo'>
                    <div className='so__landingpage_item_effect_logo_title'>
                        <p>BACKED BY SERVICES</p>
                        <p>PLATFORM TRUSTED BY</p>
                    </div>
                    <div className='so__landingpage_item_effect_logo_image'>
                        <img src={logo1} alt="" />
                        <img src={logo2} alt="" />
                        <img src={logo3} alt="" />
                        <img src={logo4} alt="" />
                    </div>
                </div>
            </div>
            <div className='so__landingpage_item_roi'>
                <div className='so__landingpage_item_roi_border_body'>
                    <div className='so__landingpage_item_roi_border_body_text'>
                        <p className='so__landingpage_item_roi_border_body_text_title'>“For business that spends $1 out of every $10 <br/> they earn on marketing, it’s time to get SMS.”</p>
                        <p className='so__landingpage_item_roi_border_body_text_title_underline'></p>
                        <p className='so__landingpage_item_roi_border_body_text_desc'><span>Short</span>, and <span>sweet</span> with zero effort. </p>
                        <div className='so__landingpage_item_roi_border_body_button'>
                            <p>200x ROI. Here I come!</p>
                        </div>
                    </div>
                    <div className="card_lading card-bottom-left">
                        <div className="card-inner">
                            <img src={count} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='so__landingpage_item_world'>
                <p className='so__landingpage_item_world_title'>No spammy. <span>Direct</span>. Personalized. <span>Mobile-first</span>. <br/>
                    <span>The future of eCommerce.</span> 
                </p>
                <div className='so__landingpage_item_world_count'>
                    <div className='so__landingpage_item_world_count_first'>
                        <p id="percent">0</p>
                        <p>Open rate</p>
                        <p>(email is far behind)</p>
                    </div>
                    <div className='so__landingpage_item_world_count_second'>
                        <p id='min'>0</p>
                        <p><span>3%</span> are <span>read</span> </p>
                        <p>after sending</p>
                    </div>
                    <div className='so__landingpage_item_world_count_third'>
                        <p id='lastPercent'>0</p>
                        <p>Engagement.</p>
                    </div>
                </div>
                <p className='so__landingpage_item_world_bottom_tittle'>What’s <span>out of the box</span> <img src={lightBulb} alt=""></img> with SMSGem?</p>
            </div>
            <div className='so__landingpage_item_world_cardboard'>
                <div id="card1__dashboard" className='so__landingpage_item_world_cardboard_item'>
                    <div>
                        <p className='cardboard_title'>Collect more fan</p>
                        <p className='cardboard_desc'>Grow your <span className='cardboard_desc_yellow'>audience lists</span> with our</p>
                        <p className='cardboard_desc'>beautiful and <span className='cardboard_desc_pink'>compliance-pressed</span> tools</p>
                    </div>
                    <div>
                        <img src={gifCard_1} alt="" />
                    </div>
                </div>
                <div id="card2__dashboard" className='so__landingpage_item_world_cardboard_item'>
                    <div>
                        <p className='cardboard_title'>Recover lost sales</p>
                        <p className='cardboard_desc'>Recover your lost sale by a <span className='cardboard_desc_yellow'>sweet & short</span></p>
                        <p className='cardboard_desc'>nudge to <span className='cardboard_desc_pink'>abandoned</span> shoppers</p>
                    </div>
                    <div>
                        <img src={gifCard_2} alt="" />
                    </div>
                </div>
                <div id="card3__dashboard" className='so__landingpage_item_world_cardboard_item'>
                    <div>
                        <p className='cardboard_title'>Be-friend with your fan</p>
                        <p className='cardboard_desc'>Build next-level texts that <span className='cardboard_desc_yellow'>engage</span> with</p>
                        <p className='cardboard_desc'>personalized and <span className='cardboard_desc_pink'>onbrand</span> content</p>
                    </div>
                    <div>
                        <img src={gifCard_3} alt="" />
                    </div>
                </div>
            </div>
            <div className='so__landingpage_phone_message'>
                <div className='so__landingpage_phone_message_sent'>
                    <div>
                        <p className='so__landingpage_phone_message_sent_tittle'>
                            Want something hot? <br />
                            Get our welcome coffee <img src={coffee_image} alt=""></img> 
                        </p>
                        <p className='so__landingpage_phone_message_sent_desc'>With merchant-centered approach, we work hard to bring your <span className='blue_color'>one more big dollar</span> sale channel with <span className='pink_color'>less work</span>.</p>
                    </div>
                 
                    <p className='so__landingpage_phone_message_sent_policy'>
                        *By submitting phone number, I agree to receive recurring marketing messages at the phone provided. Reply X to cancel. View our <a className='blue_color' href="http://" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and <a className='blue_color' href="http://" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                    </p>

                </div>
                <div className='so__landingpage_phone_message_phone'>
                    <img src={phone_sent} alt="" />
                </div>
            </div>
        </div>
    )
}

export default DashboardBody
