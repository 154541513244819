import React from 'react'
import { useEffect } from 'react'
import airplane from '../../assets/image/dashboard/dashboardFooter/AirplaneDeparture.svg'

function DashboardFooter({stateUpdate, setStateUpdate}) {
    const activeAnimation = () => {
        const mainAddKeyframe = document.querySelector('.animate_omg_airplane');
        mainAddKeyframe.append(
            Object.assign(document.createElement("style"), {
              textContent: `@keyframes dash { to { stroke-dashoffset: 0}}`
            }),
        )

        mainAddKeyframe.append(
            Object.assign(document.createElement("style"), {
              textContent: `@keyframes dash_circle { from { opacity: 0} to {opacity : 1}}`
            }),
        )
    }
    useEffect(() => {
        if (window.location.pathname.includes('pricing')) {
            activeAnimation()
        }
        if (stateUpdate) {
            activeAnimation();
            setStateUpdate(false);
        }  
    }, [stateUpdate])

    return (
        <div className='ft__wrapper_all'>
            <div className='ft_header'>
                <div className='animate_omg_airplane'>
                    <div className='animate_omg_airplane_header'>
                        <p className='ft_header_title'>
                            Hop on! We’ll be your copilot <img src={airplane} alt=""></img> <br />
                            on <span className='yellow_color'>ecom-growth</span> path.
                        </p>
                        <div className='ft_header_button'>
                            <p><a target={'_blank'} rel="noreferrer" href="https://go.crisp.chat/chat/embed/?website_id=a4bdf13a-e64f-4660-9d3a-b282560b6427">Let’s get on</a></p>
                        </div>
                    </div>
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="line-graph anime js-animate"
                    viewBox="0 0 1440 600"
                    >
                    <g style={{ isolation: "isolate" }}>
                        <path
                        fill="none"
                        stroke="#55E3E9"
                        strokeWidth="3"
                        d="M.48 599s974 0 1474-631"
                        className="line-graph__stroke line-graph__stroke-1"
                        ></path>
                        <path
                        fill="none"
                        stroke="#55E3E9"
                        strokeWidth="3"
                        d="M.48 599s998 0 1499-524"
                        className="line-graph__stroke line-graph__stroke-2"
                        style={{ isolation: "isolate" }}
                        opacity="0.16"
                        ></path>
                        <path
                        fill="none"
                        stroke="#55E3E9"
                        strokeWidth="3"
                        d="M.48 599s981 0 1513-369"
                        className="line-graph__stroke line-graph__stroke-3"
                        style={{ isolation: "isolate" }}
                        opacity="0.08"
                        ></path>
                        <path
                        fill="none"
                        stroke="#55E3E9"
                        strokeWidth="3"
                        d="M.48 599s946 0 1530-157"
                        className="line-graph__stroke line-graph__stroke-4"
                        style={{ isolation: "isolate" }}
                        opacity="0.04"
                        ></path>
                        <circle
                        cx="1121.07"
                        cy="279.12"
                        r="11.5"
                        fill="#55E3E9"
                        className="circle_delay"
                        data-animation-delay=".3s"
                        ></circle>
                    </g>
                    </svg>
                </div>
                <div className='ft__list_footer'>
                    <div className='ft__list_footer_card'>
                        <p className='ft__list_footer_card_title'>SOLUTIONS</p>
                        <p>Growth</p>
                        <p>Convert</p>
                        <p>Engage</p>
                        <p>Engage</p>
                    </div>
                    <div className='ft__list_footer_card'>
                        <p className='ft__list_footer_card_title'>LEARN</p>
                        <p>Blog</p>
                        <p>Guides</p>
                        <p>Text Library</p>
                    </div>
                    <div className='ft__list_footer_card'>
                        <p className='ft__list_footer_card_title'>PRODUCT</p>
                        <p><a target={'_blank'} rel="noreferrer" href="https://apps.shopify.com/facebook-chat-1?surface_detail=sms&surface_type=landing">Live chat</a></p>
                        <p><a target={'_blank'} rel="noreferrer" href="https://apps.shopify.com/order-tagger-by-omega?surface_detail=sms&surface_type=landing">Automation</a></p>
                        <p><a target={'_blank'} rel="noreferrer" href="https://apps.shopify.com/request-for-quote-by-omega?surface_detail=sms&surface_type=landing">B2B Solutions</a></p>
                    </div>
                    <div className='ft__list_footer_card'>
                        <p className='ft__list_footer_card_title'>COMPANY</p>
                        <p><a target={'_blank'} rel="noreferrer" href="https://omegatheme.com/">About us</a></p>
                        <p><a target={'_blank'} rel="noreferrer" href="https://apps.shopify.com/partners/omegaapps">Shopify partner</a></p>
                    </div>
                </div>
                <div className='ft__list_footer_copyright'>
                    <p className='ft__list_footer_copyright_left'>Copyright © 2022 Omega all rights reserved</p>
                    <div className='ft__list_footer_copyright_right'>
                        <p><a href="https://smsgem.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer">Terms of services</a></p>
                        <p><a href="https://smsgem.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy policy</a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardFooter
