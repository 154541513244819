import React from 'react';
import DashboardFooter from './DashboardFooter';
import DashboardHead from './DashboardHead';
import DashboardBody from './DashboardBody/DashboardBody';
import Pricing from './DashboardBody/Pricing';
import {Route, Routes, Navigate} from 'react-router-dom'
import { useState } from 'react';

function Dashboard() {
    const [stateUpdate, setStateUpdate] = useState(false);
    return (
        <div className='dd__wrapper_all'>
            <DashboardHead/>
            <Routes>
				<Route path="/solution" element={<DashboardBody setStateUpdate={setStateUpdate}/>}/>
				<Route path="/pricing" element={<Pricing />}/>
				<Route path="*" element={<Navigate to="solution" />}/>
			</Routes>
            <DashboardFooter stateUpdate={stateUpdate} setStateUpdate={setStateUpdate}/>
        </div>
    )
}

export default Dashboard
