import React from 'react';
import Astronauts from '../assets/image/notfound/Astronaut.svg'
import Logo from '../assets/image/notfound/Logo.svg'
import { NavLink } from 'react-router-dom';

function Notfound() {
    return (
        <div className='not_found_wrapper'>
            <div className='not_found_wrapper_all'>
                <div className='not_found_body_head'>
                    <img src={Logo}
                        alt=""/>
                </div>
                <div className='not_found_body_bottom'>
                    <div className='not_found_body_bottom_left'>
                        <p className='not_found_body_bottom_left_title'>Oops! No signal here yet!</p>
                        <p className='not_found_body_bottom_left_desc'>
                            We’re trying to send SMS to outer space friends!<br/>Back to Earth soon!</p>
                        <NavLink to='/dashboard/solution' className="nav-link active" aria-current="page">Wander around?</NavLink>
                    </div>
                    <div className='not_found_body_bottom_right'>
                        <img src={Astronauts} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notfound
