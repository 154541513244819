import React from 'react'
import { useNavigate } from 'react-router-dom';

function Message() {
    const navigate = useNavigate()
    const listMessage = [
        {
            message: 'Hi there 👋',
            bottom: 60,
            time: 500
        }, 
        {
            message: 'Welcome to SMS World',
            bottom: 50,
            time: 1000
        }, 
        {
            message: '...',
            bottom: 40,
            left: 20,
            time: 1000
        }, 
        {
            id:1,
            message: 'Relax, bud. We’re here to help. 😉',
            bottom: 30,
            time: 2000
        }, 
    ];

    var myArrayOfDomNodes = Array.from(listMessage)

    function addClassAfterTwoSeconds(el) {
        var tagRight = document.createElement('p')
        tagRight.classList.add('message_wrapper_item')
        tagRight.innerHTML = el.message
        
        if (el.message === '...') {
            tagRight.classList.add('message_wrapper_item_dot')
        } 

        return new Promise(res => {
            setTimeout(_ => {
                document.querySelector('#message_wrapper').appendChild(tagRight)
                if (el.message === '...') {
                    var dotType = document.createElement('span')
                    dotType.classList.add('dot_typing')
                    document.querySelector('.message_wrapper_item_dot').appendChild(dotType)
                }
                setTimeout(() => {
                    tagRight.style.display = 'block'
                    tagRight.style.bottom = `${el.bottom}%`
                    if (el.message === '...') {
                        tagRight.style.bottom = `${el.bottom}%`
                        tagRight.style.left = `${el.left}px`
                        tagRight.style.color = 'transparent'
                          document.querySelector('.message_wrapper_item_dot').classList.add('custom_bubble')
                    } else if (el.id === 1) {
                        document.querySelector('.message_wrapper_item_dot').classList.add('hide')
                        tagRight.style.bottom = "40%"
                    } 
                }, 1000);
                res();
            }, el.time);
        });
    }

    addClassesSequentially(myArrayOfDomNodes).then(() => setTimeout(() => {
        navigate('/dashboard')
    }, 3000))

    function addClassesSequentially(sequence) {
        let item = sequence.shift();
        return item ? addClassAfterTwoSeconds(item).then(addClassesSequentially.bind(null, sequence)) : Promise.resolve();
    }

    return (
        <div id="message_wrapper" className='message_wrapper'>
        </div>
    )
}

export default Message
