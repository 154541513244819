import React from 'react'
import logo from '../../assets/image/notfound/Logo.svg';
import { NavLink } from "react-router-dom";

function DashboardHead() {
    return (
        <div className='hd__landingpage_wrapper'>
           <nav className="navbar navbar-expand-lg navbar-dark">
            <div className="container-fluid">
                <a href={() => false} className="navbar-brand">
                    <img src={logo} alt="" />
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <NavLink to='/dashboard/solution' className="nav-link active" aria-current="page">Solutions</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to='/dashboard/pricing' className="nav-link active">Pricing</NavLink>
                    </li>
                    <li className="nav-item"> 
                        <NavLink to='/library' className="nav-link active">Library</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink onClick={() => window.open('https://apps.shopify.com/smsgem-subscribers?surface_detail=web&surface_type=header', 'blank')} to='/dashboard/solution' className="nav-link">Yes, it’s free</NavLink>
                    </li>
                </ul>
                </div>
            </div>
            </nav>
        </div>
    )
}

export default DashboardHead
